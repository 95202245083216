import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import { FlagOutlined } from '@ant-design/icons';

type AppealInfoProps = {
  status: 'pending' | 'canceled' | 'review' | 'finished';
  createdAt: Date;
  expiresAt: Date;
}

const boldStyle = {
  fontSize: '14px',
  fontWeight: 600,
};

const normalStyle = {
  fontSize: '12px',
  fontWeight: 500,
};

export const AppealInfo: FC<AppealInfoProps> = ({
  status,
  createdAt,
  expiresAt,
}) => {
  const createdTimePast = moment(createdAt).fromNow();
  const createdDateFormatted = moment(createdAt).format('DD/MM/YYYY HH:mm');
  const expiresAtInBrasilia = moment(expiresAt).add(3, 'hours');
  const expiresIn = expiresAtInBrasilia.fromNow();
  const expiresDate = expiresAtInBrasilia.format('DD/MM/YYYY');

  const map: Record<'pending' | 'canceled' | 'review' | 'finished', string> = {
    pending: 'Recebido',
    canceled: 'Cancelado',
    review: 'Em análise',
    finished: 'Respondido',
  };
  const statusStyles: Record<'pending' | 'canceled' | 'review' | 'finished', string> = {
    pending: '#F5E388',
    canceled: '#E2E2E9',
    review: '#C9E6FF',
    finished: '#B1F1C1',
  };

  return (
    <Col style={{ width: '240px' }}>
      <div style={{ display: 'inline-block' }}>
        <Row style={{
          borderRadius: '50px',
          backgroundColor: statusStyles[status],
          padding: ' 4px 16px',
          flexWrap: 'nowrap',
        }}
        >
          <FlagOutlined style={{ fontSize: '17px', marginRight: '4px' }} />
          <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
            {map[status]}
          </Typography>
        </Row>
      </div>
      <Typography style={boldStyle}>{createdTimePast}</Typography>
      <Typography style={normalStyle}>{createdDateFormatted}</Typography>
      <Typography style={boldStyle}>{`Vencimento: ${expiresIn}`}</Typography>
      <Typography style={normalStyle}>{expiresDate}</Typography>
    </Col>
  );
};
