import {
  FC,
  useCallback,
} from 'react';
import {
  Col, Divider, Row,
  Space,
} from 'antd';
import { ExamBookletObjectType, useCreateExamBooklet } from '../../../../api/appeal';
import CreateBooklistForm from './CreateBooklistForm';
import BookletItem from './BookletItem';

type BookletListParams = {
  data: ExamBookletObjectType[],
  creatingMode: boolean,
  onHideCreatingMode: () => void,
  onDelete: (id: string) => void;
  onRefetch: () => Promise<void>;
}

const BookletList: FC<BookletListParams> = ({
  data, creatingMode, onHideCreatingMode, onDelete, onRefetch,
}) => {
  const [create] = useCreateExamBooklet();

  const handleCreate = useCallback(async (v: ExamBookletObjectType) => {
    try {
      await create({
        variables: {
          input: {
            institution: v.institutionTarget,
            name: v.name,
            totalQuestions: v.totalQuestions,
            year: v.year,
            examVersion: v.examVersion,
            dateLimitToCreateAppeal: v.dateLimitToCreateAppeal,
          },
        },
      });
      await onRefetch();
    } catch (e) {
      console.log(e);
    }
  }, [create, onRefetch]);

  return (
    <Col
      style={{
        background: '#F7F2FA',
        borderRadius: 12,
        padding: '12px 0 24px 0',
        margin: '32px 0',
      }}
    >
      <Row style={{ fontWeight: 'bold' }} align="middle" className="select-full">
        <Space style={{ padding: '0 8px 0 16px', flex: 1 }}>
          UF
        </Space>
        <Space style={{ padding: '0px 8px', flex: 4 }}>
          Instituição
        </Space>
        <Space style={{ padding: '0px 8px', flex: 1 }}>
          Ano da prova
        </Space>
        <Space style={{ padding: '0px 8px', flex: 1 }}>
          Número de questões objetivas
        </Space>
        <Space style={{ padding: '0px 8px', flex: 1 }}>
          Edição da prova
        </Space>
        <Space style={{ padding: '0px 8px', flex: 1 }}>
          Data limite
        </Space>
        <Space style={{ padding: '0px 8px', flex: 2 }}>
          Caderno da prova
        </Space>
        <Space style={{ padding: '0px 16px 0 8px', flex: 1 }}><div /></Space>
      </Row>
      {
        creatingMode && (
          <>
            <Divider />
            <CreateBooklistForm
              onClose={onHideCreatingMode}
              onConfirm={handleCreate}
            />
          </>
        )
      }
      {
        data.map(e => (
          <div key={e._id}>
            <Divider />
            <BookletItem data={e} onDelete={() => onDelete(e._id)} />
          </div>
        ))
      }
    </Col>
  );
};

export default BookletList;
