import {
  FC, useCallback, useContext, useMemo,
} from 'react';
import {
  Button, Dropdown, MenuProps, Row, Spin, Typography,
} from 'antd';
import {
  faComment, faEllipsisV, faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CommentAnswerStatusType, ReservedByType } from '../../../hooks/useCommentsManagement';
import { ForumContext } from '../../../contexts/Forum.context';
import { useProfile } from '../../../api/profile';

const { Text } = Typography;

type ForumListButtonsProps = {
  commentId: string;
  status: CommentAnswerStatusType;
  reservedBy?: ReservedByType;
  area: string;
}

export const ForumListButtons: FC<ForumListButtonsProps> = ({
  commentId,
  status,
  reservedBy,
  area,
}) => {
  const {
    setCommentAsIgnored,
    setCommentAsPrivate,
    setCommentAsReserved,
    isSendingData,
  } = useContext(ForumContext);

  const { data } = useProfile();

  const openInNewTap = useCallback((): void => {
    const hostUrl = process.env.REACT_APP_ENV === 'PROD' ? 'https://jj-prod-e38e7-admin.web.app' : 'https://jj-dev-a2b7a-admin.web.app';
    const path = '/admin/responder-comentario';
    const url = `${hostUrl}${path}?commentId=${commentId}&area=${area}`;

    window.open(url, '_blank', 'noreferrer');
  }, [area, commentId]);

  const hasNotBeingReserverYet = !reservedBy?.userId;
  const isWaitingAnswer = useMemo(() => {
    return status.answerStatus === 'waitingAnswer' || status.answerStatus === 'monitorRequestedByUser';
  }, [status]);
  const isReservedByUser = data !== undefined && data.profile._id === reservedBy?.userId;

  // Verifica se o tempo de reserva já expirou
  const hasExpired = useMemo(() => {
    return !hasNotBeingReserverYet && moment(reservedBy.expiresAt!).isBefore(moment(Date()));
  }, [hasNotBeingReserverYet, reservedBy]);

  // Verifica se o botão deve ser exibido
  const showButton = useMemo(() => {
    return isWaitingAnswer && (isReservedByUser || hasNotBeingReserverYet || hasExpired);
  }, [isWaitingAnswer, isReservedByUser, hasNotBeingReserverYet, hasExpired]);

  // Verifica se o item está reservado, mas o botão não deve ser exibido
  const showReserved = useMemo(() => {
    return !showButton && !!reservedBy?.userId && isWaitingAnswer;
  }, [showButton, reservedBy, isWaitingAnswer]);

  const showVisualize = useMemo(() => {
    return status.answerStatus !== 'waitingAnswer' && status.answerStatus !== 'monitorRequestedByUser';
  }, [status]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: isSendingData ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Row
          style={{ margin: '8px', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faComment} style={{ marginRight: '8px' }} />
          <Text>Ignorar</Text>
        </Row>
      ),
      onClick: () => setCommentAsIgnored(commentId),
    }, {
      key: '2',
      label: isSendingData ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Row
          style={{ margin: '8px', alignItems: 'center' }}
        >
          <>
            <FontAwesomeIcon icon={status.isPublic ? faEyeSlash : faEye} style={{ marginRight: '8px' }} />
            <Text>{status.isPublic ? 'Tornar privado' : 'Tornar público'}</Text>
          </>
        </Row>
      ),
      onClick: () => setCommentAsPrivate(commentId),
    },
  ];

  return (
    <div style={{ width: '150px', marginRight: '16px' }}>
      <></>
      {showButton && (
        <Row align="middle" justify="space-between">
          <Button
            style={{ width: '120px' }}
            onClick={() => setCommentAsReserved(commentId)}
            shape="round"
            loading={isSendingData}
            icon={<FontAwesomeIcon icon={faComment} style={{ marginRight: '4px' }} />}
          >
            Responder
          </Button>
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
          >
            <FontAwesomeIcon icon={faEllipsisV} size="lg" style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Row>
      )}

      {showReserved && (
        <>
          <Text>Reservado por </Text>
          <Text style={{ fontWeight: 'bold' }}>{reservedBy?.userName}</Text>
          <Text> até </Text>
          <Text style={{ fontWeight: 'bold' }}>{moment(reservedBy?.expiresAt).format('h:mm')}</Text>
        </>
      )}

      {showVisualize && (
      <Button
        style={{ width: '120px' }}
        onClick={openInNewTap}
        shape="round"
        loading={isSendingData}
        icon={<FontAwesomeIcon icon={faComment} style={{ marginRight: '4px' }} />}
      >
        Visualizar
      </Button>
      )}
    </div>
  );
};
