import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Col, Divider, Input, message, Row,
  Select,
  SelectProps,
  Typography,
} from 'antd';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { getParam } from '../../../hooks/useSearchParam';
import { useAppealFinishedList, useCreateAppealByAdmin, useEditRespondedAppeal } from '../../../api/appeal';
import { AristoMarkdownEditor } from '../../../components/AristoMarkdownEditor';
import Markdown from '../../../components/Markdown';
import { ReturnButton } from '../components/ReturnButton';

export const AppealCommentCreation: FC = () => {
  const createExam = getParam('createExam');
  const createQuestion = getParam('createComment');
  const appealId = getParam('appealId');
  const uf = getParam('uf');
  const year = getParam('year');
  const majorArea = getParam('area');
  const institutionName = getParam('institution');

  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [replyLink, setReplyImageLink] = useState<string>('');
  const [linkMarkdown, setLinkMarkdown] = useState<string>('');

  const fullComment = useMemo(() => {
    return linkMarkdown + comment;
  }, [comment, linkMarkdown]);

  const { data, loading, error } = useAppealFinishedList([appealId].filter(e => e.length > 0));
  const [editRespondedAppeal] = useEditRespondedAppeal();
  const [createAppealByAdmin] = useCreateAppealByAdmin();

  const questionNumber = getParam('questionNumber');
  const examType = getParam('examType');
  const qAppeal = getParam('canAppeal');
  const examBooklet = getParam('examBooklet');

  const navigate = useHistory();

  const [isAppealAccepted, setIsAppealAccepted] = useState<string | undefined>(qAppeal !== '' ? qAppeal : undefined);

  const options: SelectProps['options'] = [
    {
      value: 'true',
      label: <span>Sim</span>,
    },
    {
      value: 'false',
      label: <span>Não</span>,
    },
  ];

  useEffect(() => {
    if (data && !error && !!data.appealFinishedList.length) {
      const appeal = data.appealFinishedList[0];

      const cleanedComment = appeal.moderatorResponse.replace(/!\[.*?\]\(\S+\)\n\n/, '');
      const match = appeal.moderatorResponse.match(/!\[.*?\]\((\S+)\)/);
      const link = match ? match[1] : null;
      setComment(cleanedComment);
      if (link) {
        setReplyImageLink(link);
        setLinkMarkdown(`![ ](${link})\n\n`);
      }
      setIsAppealAccepted(appeal.isAppealAccepted ? 'true' : 'false');
    }
  }, [data, error]);

  const handleCreateQuestion = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      await createAppealByAdmin({
        variables: {
          input: {
            examVersion: examType,
            institution: {
              institutionName,
              uf,
            },
            isAppealAccepted: isAppealAccepted === 'true',
            majorArea,
            questionNumber: Number(questionNumber),
            response: fullComment,
            year: Number(year),
          },
        },
      });
      navigate.goBack();
      message.success(!createExam ? 'Comentário criado!' : 'Prova e comentário criado!');
    } catch (e) {
      message.error(`Erro ao criar ${!createExam ? 'o comentário' : 'a prova'}!`);
    }
    setIsLoading(false);
  }, [createAppealByAdmin, createExam, examType, fullComment, institutionName, isAppealAccepted, majorArea, navigate, questionNumber, uf, year]);

  const handleEditAppeal = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      await editRespondedAppeal({
        variables: {
          appealId,
          editRespondedAppealInput: {
            isAppealAccepted: isAppealAccepted === 'true',
            response: fullComment,
          },
        },
      });
      navigate.goBack();
      message.success('Comentário editado!');
    } catch (e) {
      message.error('Erro ao editar o comentário!');
    }
    setIsLoading(false);
  }, [appealId, editRespondedAppeal, fullComment, isAppealAccepted, navigate]);

  const handleSend = useCallback(async (): Promise<void> => {
    if (createExam.length || createQuestion.length) {
      await handleCreateQuestion();
      return;
    }

    await handleEditAppeal();
  }, [createExam.length, createQuestion.length, handleCreateQuestion, handleEditAppeal]);

  const title = createExam.length ? `Questão ${questionNumber} - Prova tipo ${examType}` : undefined;

  const disabled = useMemo(() => {
    return !comment.length || isLoading || loading || isAppealAccepted === undefined || linkMarkdown.length === 0;
  }, [comment.length, isAppealAccepted, isLoading, linkMarkdown.length, loading]);

  const handleAddUrl = useCallback(() => {
    setLinkMarkdown(`![ ](${replyLink})\n\n`);
  }, [replyLink]);

  return (
    <Col style={{ maxWidth: '1200px', margin: '0px auto' }} className="select-full">
      <ReturnButton title={title ?? ''} subTitle={`${institutionName} / ${year}`} />
      <Typography style={{ fontSize: '24px', fontWeight: 700, color: 'red' }}>{examBooklet}</Typography>
      <Col style={{
        padding: '16px',
        borderRadius: '16px',
        margin: '16px 0px',
        background: '#F9F9FF',
      }}
      >
        <Typography style={{ marginBottom: '8px', fontSize: 16 }}>Cabe Recurso</Typography>
        <Select
          style={{ width: '100%' }}
          placeholder="escolha uma opção"
          options={options}
          value={isAppealAccepted}
          onChange={setIsAppealAccepted}
        />
        <Divider style={{ margin: '16px 0px' }} />
        <Typography style={{ marginBottom: '8px', fontSize: 16 }}>Questão</Typography>
        <Row gutter={24}>
          <Col span={14}>
            <Typography
              style={{
                marginBottom: '8px',
                fontSize: 12,
                background: '#D9E3FD',
                padding: '4px 8px',
                borderRadius: 4,
              }}
            >
              Insira abaixo o link da imagem da questão e das alternativas
            </Typography>
            <Input
              type="text"
              placeholder="Link da imagem"
              value={replyLink}
              onChange={e => setReplyImageLink(e.target.value.replaceAll(')', '%29').replaceAll('(', '%28'))}
              style={{ marginBottom: 8 }}
            />
            <Row justify="end">
              <Button
                disabled={replyLink.length === 0}
                shape="round"
                type="primary"
                onClick={handleAddUrl}
                className="my-button"
              >
                Adicionar imagem
              </Button>
            </Row>
          </Col>
          <Col span={10}>
            <Markdown
              style={{
                minHeight: '300px',
                padding: '16px',
              }}
              saveOnStorage={false}
            >
              {linkMarkdown}
            </Markdown>
          </Col>
        </Row>
        <Divider style={{ margin: '16px 0px' }} />
        <Row wrap={false}>
          <div style={{ width: '50%' }}>
            <AristoMarkdownEditor
              body={comment}
              onUpdate={setComment}
              disabled={isLoading || loading}
            />
          </div>
          <div style={{ width: '50%' }}>
            <Markdown
              style={{
                marginTop: '40px',
                minHeight: '300px',
                padding: '16px',
              }}
              saveOnStorage={false}
            >
              {comment}
            </Markdown>
          </div>
        </Row>
        <Row justify="end">
          <Button
            disabled={disabled}
            shape="round"
            icon={<FontAwesomeIcon icon={faCommentDots} style={{ marginRight: '8px' }} />}
            type="primary"
            onClick={handleSend}
            className="my-button"
          >
            Responder
          </Button>
        </Row>
      </Col>
    </Col>
  );
};
