function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // meses começam em 0
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatRelativeDate(date: Date, formateDate?: boolean): string {
  const now = new Date();
  const diffInMs = now.getTime() - date.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays < 0) {
    return '';
  }

  const formats = [
    { limit: 0, message: () => 'hoje' },
    { limit: 1, message: () => 'ontem' },
    { limit: 6, message: (days: number) => `${days} dias` },
    { limit: 30, message: (days: number) => `${Math.floor(days / 7)} ${Math.floor(days / 7) === 1 ? 'semana' : 'semanas'}` },
    { limit: 365, message: (days: number) => `${Math.floor(days / 30)} ${Math.floor(days / 30) === 1 ? 'mês' : 'meses'}` },
    { limit: Infinity, message: (days: number) => `${Math.floor(days / 365)} ${Math.floor(days / 365) === 1 ? 'ano' : 'anos'}` },
  ];

  const format = formats.find(it => diffInDays <= it.limit);

  if (!format?.message(diffInDays)) {
    return '';
  }
  return formateDate ? `${formatDate(date)} (${(format?.message(diffInDays) || '')})` : (format?.message(diffInDays) || '');
}
