import { CloseOutlined, QuestionCircleFilled, SaveOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import {
  Button, message, Row, Space, Table, Tooltip, Typography,
} from 'antd';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  CourseConfigInfoInput,
  CourseConfigInfoOutput,
  CourseConfigInputType,
  CourseManyInfo, useCoursesManyInfo, useUpdateCourseConfig,
} from '../../../api/courses';
import { WeekGoalInputProps, WeekGoalInput } from './WeekGoalInput';

const { Text } = Typography;

interface TrophyGoalsTableProps {
  course: CourseManyInfo;
  courseConfig?: CourseConfigInputType;
  refetchCourseConfig?: (variables?: Partial<CourseConfigInfoInput> | undefined) => Promise<ApolloQueryResult<CourseConfigInfoOutput>>;
}

const initialTrophyConfig = {
  theoreticalStudy: { bronze: 0, silver: 0, gold: 0 },
  theoreticalReview: { bronze: 0, silver: 0, gold: 0 },
  smartReview: { bronze: 0, silver: 0, gold: 0 },
  exam: { bronze: 0, silver: 0, gold: 0 },
};

const goalsData: { key: TypicalWeekActivityType, type: string }[] = [
  { key: 'theoreticalStudy', type: 'Estudo teórico' },
  { key: 'smartReview', type: 'Revisão inteligente' },
  { key: 'theoreticalReview', type: 'Revisão teórica' },
  { key: 'exam', type: 'Prova' },
];

type TrophyInfoRowType = {
  value: number,
  onChange: (value: number) => void,
}

type ColumnInfoType = {
  bronze: TrophyInfoRowType,
  silver: TrophyInfoRowType,
  gold: TrophyInfoRowType,
  type: string,
  key: TypicalWeekActivityType,
};

const trophyTypesArray: TrophyType[] = ['bronze', 'silver', 'gold'];
const weekActivTypesArray: TypicalWeekActivityType[] = ['theoreticalStudy', 'theoreticalReview', 'smartReview', 'exam'];
export type TrophyType = 'bronze' | 'silver' | 'gold';
export type TypicalWeekActivityType = 'theoreticalStudy' | 'theoreticalReview' | 'smartReview' | 'exam';

export function TrophyGoalsTable({
  course, courseConfig, refetchCourseConfig,
}: TrophyGoalsTableProps) {
  const [dirtyInputs, setDirtyInputs] = useState(false);
  const [updating, setUpdating] = useState(false);
  const handleUpdateCourseConfig = useUpdateCourseConfig();
  const [courseTrophyGoals, setCourseTrophyGoals] = useState(initialTrophyConfig);

  useEffect(() => {
    if (courseConfig?.achievementGoals) {
      setCourseTrophyGoals(courseConfig.achievementGoals);
    }
  }, [courseConfig]);

  const isSaveDisabled = useMemo(() => {
    const existsBronzeGreaterThanSilver = Object.keys(courseTrophyGoals)
      .map(key => {
        const { bronze, silver, gold } = courseTrophyGoals[key as keyof typeof courseTrophyGoals];
        return bronze > silver || silver > gold;
      })
      .some(x => x);
    return existsBronzeGreaterThanSilver;
  }, [courseTrophyGoals]);

  const handleChange = useCallback((activityType: TypicalWeekActivityType, trophyType: TrophyType, value: number) => {
    setCourseTrophyGoals(prevCourseTrophyGoals => {
      if (weekActivTypesArray.includes(activityType)) {
        return {
          ...prevCourseTrophyGoals,
          [activityType]: {
            ...prevCourseTrophyGoals[activityType],
            ...Object.fromEntries([[trophyType, value]]),
          },
        };
      }
      return prevCourseTrophyGoals;
    });
    setDirtyInputs(true);
  }, []);

  const columns = [
    {
      title: 'Atividades',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Bronze',
      dataIndex: 'bronze',
      key: 'bronze',
      render: (record: WeekGoalInputProps) => <WeekGoalInput {...record} min={0} />,
    },
    {
      title: 'Prata',
      dataIndex: 'silver',
      key: 'silver',
      render: (record: WeekGoalInputProps, info: ColumnInfoType) => {
        const bronzeValue = courseTrophyGoals[info.key].bronze;
        return <WeekGoalInput {...record} min={bronzeValue} />;
      },
    },
    {
      title: 'Ouro',
      dataIndex: 'gold',
      key: 'gold',
      render: (record: WeekGoalInputProps, info: ColumnInfoType) => {
        const silverValue = courseTrophyGoals[info.key].silver;
        return <WeekGoalInput {...record} min={silverValue} />;
      },
    },
  ];

  const TrophyGoalsData = useMemo(() => {
    return goalsData.map(({ key, type }) => {
      const { bronze, silver, gold } = trophyTypesArray
        .reduce((acc, currTrophyType) => {
          acc[currTrophyType] = {
            value: courseTrophyGoals[key][currTrophyType],
            onChange: (value: number) => handleChange(key, currTrophyType, value),
          };
          return acc;
        }, {} as Record<TrophyType, TrophyInfoRowType>);
      return {
        key, type, bronze, silver, gold,
      };
    });
  }, [courseTrophyGoals, handleChange]);

  const handleUndo = useCallback(() => {
    if (course) {
      setCourseTrophyGoals(courseConfig?.achievementGoals || initialTrophyConfig);
      setDirtyInputs(false);
    }
  }, [course, courseConfig?.achievementGoals]);

  const handleUpdate = useCallback(async () => {
    if (!course) {
      return;
    }
    setUpdating(true);
    try {
      await handleUpdateCourseConfig({
        courseId: course._id,
        achievementGoals: courseTrophyGoals,
      });
      if (refetchCourseConfig) {
        await refetchCourseConfig({
          courseId: course._id,
        });
      }
      setDirtyInputs(false);
      message.success('Configuração de troféu atualizada com sucesso');
    } catch (error) {
      message.error('Erro ao salvar configurações de troféu!');
      console.error(error);
    } finally {
      setUpdating(false);
    }
  }, [course, courseTrophyGoals, handleUpdateCourseConfig, refetchCourseConfig]);

  return (
    <Space direction="vertical">
      <Row>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={TrophyGoalsData}
        />
      </Row>
      <Row>
        <Space className="mt-2">
          <Button
            type="ghost"
            icon={<CloseOutlined />}
            disabled={!dirtyInputs}
            onClick={handleUndo}
          >
            Desfazer
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={!dirtyInputs || isSaveDisabled || updating}
            onClick={handleUpdate}
            loading={updating}
          >
            Salvar
          </Button>
        </Space>
      </Row>
    </Space>
  );
}
