import { FC, useState } from 'react';
import {
  Button, Row, Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { StopOutlined } from '@ant-design/icons';
import { AppealInfo } from './AppealInfo';
import { ExamInfo } from './ExamInfo';
import { AppealContent } from './AppealContent';
import { AppealObjectType, useUnlockAppeal } from '../../../../api/appeal';
import { AppealCancelDialog } from './AppealCancelDialog';

export const AppealAnswerCard: FC<{ data?: AppealObjectType, year: number }> = ({
  data,
  year,
}) => {
  const [isSendingData, setIsSendingData] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useHistory();

  const [unlockAppeal] = useUnlockAppeal();

  const handleUnlock = async (): Promise<void> => {
    setIsSendingData(true);
    try {
      await unlockAppeal({
        variables: {
          appealId: data?._id ?? '',
        },
      });
      navigate.push('/admin/resources/appeals');
    } catch (error) {
      console.log('Erro ao liberar recurso!');
    }
    setIsSendingData(false);
  };

  return (
    <Row style={{
      padding: '16px',
      borderRadius: '16px',
      margin: '16px 0px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      background: '#F9F9FF',
    }}
    >
      <AppealCancelDialog
        appealId={data?._id ?? ''}
        open={open}
        close={() => setOpen(false)}
      />
      <AppealContent data={data} year={year} />
      <AppealInfo
        status={data?.status ?? 'finished'}
        createdAt={data?.createdAt ?? new Date()}
        expiresAt={data?.appealEndDate ?? new Date()}
      />
      <ExamInfo
        area={data?.question.majorArea ?? ''}
        institution={data?.institution ?? ''}
        year={year}
      />
      <Space direction="vertical">
        <Button
          style={{ width: '130px' }}
          shape="round"
          icon={(
            <FontAwesomeIcon
              style={{ marginRight: '4px' }}
              icon={faComment}
            />
          )}
          loading={isSendingData}
          onClick={handleUnlock}
        >
          Desbloquear
        </Button>
        <Button
          style={{ width: '130px' }}
          shape="round"
          danger
          icon={(
            <StopOutlined style={{ marginRight: '4px' }} />
          )}
          loading={isSendingData}
          onClick={() => setOpen(true)}
        >
          Cancelar
        </Button>
      </Space>
    </Row>
  );
};
