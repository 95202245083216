import { Typography, Col, Table } from 'antd';
import { FC, useMemo } from 'react';
import { formatRelativeDate } from '../../../utils/formatRelativeDate';
import { useStudentInfoBuy, useTrialInfo } from '../../../api/courses';

type CardOfCourseParams = FC<{
  cardTitle: string,
  courseId: string,
  email: string
}>

const titleStyle = {
  fontSize: 14,
  fontWeight: 400,
  color: '#000',
};

const trialInfoContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '8px 12px',
  borderRadius: '8px',
  fontSize: '12px',
  fontWeight: 600,
  position: 'absolute' as const,
  top: '16px',
  right: '16px',
};

export const CardOfCourse: CardOfCourseParams = ({
  cardTitle,
  courseId,
  email,
}) => {
  const { data } = useStudentInfoBuy(courseId, email);

  const paymentMethod = useMemo(() => {
    switch (data?.studentInfoBuy.method) {
      case 'billet':
        return 'Boleto';
      case 'pix':
        return 'Pix';
      case 'credit_card':
        return 'Cartão de Credito';
      default:
        return 'sem registro';
    }
  }, [data?.studentInfoBuy.method]);

  const purchasedIn = useMemo(() => {
    if (!data?.studentInfoBuy.purchasedIn) { return 'sem registro'; }

    return formatRelativeDate(new Date(data.studentInfoBuy.purchasedIn), true);
  }, [data?.studentInfoBuy.purchasedIn]);

  const amountPaid = useMemo(() => {
    if (!data?.studentInfoBuy.amountPaid) { return 'sem registro'; }
    return `R$ ${data?.studentInfoBuy.amountPaid.toFixed(2)}`;
  }, [data?.studentInfoBuy.amountPaid]);

  const { trialInfo, loading: trialLoading, error } = useTrialInfo(email, courseId);

  const trialInfoText = useMemo(() => {
    if (trialLoading) {
      return null;
    }
    if (error) {
      return undefined;
    }
    if (!trialInfo) {
      return 'Período de teste não disponível';
    }

    const { daysLeft, totalTrialPeriod, expiredOn } = trialInfo;

    if (daysLeft > 0) {
      return `${daysLeft} de ${totalTrialPeriod} dias restantes`;
    }

    if (expiredOn && daysLeft <= 0) {
      const formattedExpirationDate = new Date(expiredOn).toLocaleDateString();
      return `Período de teste expirou em ${formattedExpirationDate}`;
    }

    return `${daysLeft} de ${totalTrialPeriod} dias restantes`;
  }, [trialInfo, trialLoading, error]);

  const columns = [
    {
      title: 'Pagamentos',
      dataIndex: 'trasfDate',
      key: 'trasfDate',
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Vencimento',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      render: (text: string | null) => (text ? new Date(text).toLocaleDateString() : 'sem registro'),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      render: (value: number) => `R$ ${value.toFixed(2)}`,
    },
  ];

  return (
    <div
      style={{
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Typography
          style={{ fontSize: '22px', fontWeight: 400 }}
        >
          {cardTitle}
        </Typography>

        {trialInfoText && (
          <div
            style={{
              ...trialInfoContainerStyle,
              backgroundColor: trialInfoText.includes('expirou')
                ? '#F9DBD7'
                : '#D9E3FD',
            }}
          >
            {trialInfoText.includes('expirou') ? (
              <>
                <Typography style={{ fontWeight: 400, color: '#051B3C' }}>Período de teste expirou em</Typography>
                <Typography style={{ fontSize: '14px', fontWeight: 600, color: '#051B3C' }}>
                  {trialInfoText.replace('Período de teste expirou em', '')}
                </Typography>
              </>
            ) : (
              <>
                <Typography style={{ fontWeight: 400, color: '#051B3C' }}>Período de teste:</Typography>
                <Typography style={{ fontSize: '12px', color: '#051B3C' }}>{trialInfoText}</Typography>
              </>
            )}
          </div>
        )}
      </div>

      <div style={{ flexDirection: 'row', display: 'flex', marginBottom: '20px' }}>
        <Col style={{ marginRight: '20%' }}>
          <Typography style={titleStyle}>Modalidade de Pagamento </Typography>
          <Typography style={{
            fontWeight: 600,
            fontSize: '16px',
          }}
          >
            {paymentMethod}
          </Typography>
        </Col>
        <Col style={{ marginRight: '20%' }}>
          <Typography style={titleStyle}>Adiquirido em:</Typography>
          <Typography style={{
            fontWeight: 600,
            fontSize: '16px',
          }}
          >
            {purchasedIn}
          </Typography>
        </Col>
        <Col>
          <Typography style={titleStyle}>Valor total do curso:</Typography>
          <Typography style={{
            fontWeight: 600,
            fontSize: '16px',
          }}
          >
            {amountPaid}
          </Typography>
        </Col>
      </div>
      <Table
        columns={columns}
        dataSource={data?.studentInfoBuy.paymentsObjectType}
        pagination={false}
        size="small"
      />
    </div>
  );
};
