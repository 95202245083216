import { FC, useMemo, useState } from 'react';
import {
  Col, Divider, Input, Spin,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { VisibleAppealListCard } from './VisibleAppealListCard';
import { useAppealFinishedList } from '../../../../api/appeal';

type VisibleAppealListProps = {
  uf: string,
  institution: string,
  year: number,
  appealsList: string[],
}

export const VisibleAppealList: FC<VisibleAppealListProps> = ({
  uf,
  institution,
  year,
  appealsList,
}) => {
  const [search, setSearch] = useState('');
  const { data, loading } = useAppealFinishedList(appealsList);

  const finishedAppeals = useMemo(() => {
    return data?.appealFinishedList;
  }, [data]);

  const filteredAppeals = useMemo(() => {
    if (search) {
      return finishedAppeals?.filter(it => it.moderatorResponse.includes(search));
    }

    return finishedAppeals;
  }, [search, finishedAppeals]);

  return (
    <Col>
      <Input
        style={{ marginBottom: '16px' }}
        placeholder="Pesquisar Conteúdo"
        prefix={<SearchOutlined />}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      {loading && Array.from({ length: 5 }).map((it, index) => {
        return (
          <>
            <div style={{
              height: '140px',
              width: '100%',
              borderRadius: '16px',
              backgroundColor: '#F0F0F0',
            }}
            />
            {index < 5 && <Divider style={{ height: '2px' }} />}
          </>
        );
      })}
      {!loading && filteredAppeals?.length && filteredAppeals.map((it, index) => {
        return (
          <>
            <VisibleAppealListCard
              uf={uf}
              key={it.appealId}
              institution={institution}
              year={year}
              data={it}
            />
            {index !== filteredAppeals.length - 1 && (
              <Divider />
            )}
          </>
        );
      })}
    </Col>
  );
};
