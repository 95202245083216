import {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import {
  Row,
  Col,
  Typography, Space,
  Spin,
  Collapse,
} from 'antd';
import {
  CourseLesson,
  useCourseConfigInfo,
  useCoursesManyInfo,
} from '../../api/courses';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';
import { WeekGoalsTable } from './components/WeekGoalsTable';
import { LessonsTable } from './components/LessonsTable';
import { ExamsTable } from './components/ExamsTable';
import { TrophyGoalsTable } from './components/TrophyGoalsTable';
import { AllowedActivitiesSelector } from './components/AllowedActivitiesSelector';
import { CourseGeneralInformation } from './components/CourseGeneralInformation';
import { PlannersTable } from './components/PlannersTable';

type EditCoursePageProps = {}

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

export function EditCoursePage(props: EditCoursePageProps) {
  const [courseLessons, setCourseLessons] = useState([] as CourseLesson[]);
  const courseId = getParam('id');
  const { data: configData, refetch: refetchCourseConfig, loading: loadingConfig } = useCourseConfigInfo(courseId);
  const [coursesManyInfoquery, { data, loading, refetch }] = useCoursesManyInfo();

  useEffect(() => {
    if (courseId) {
      coursesManyInfoquery({
        variables: {
          ids: [courseId],
        },
      });
    }
  }, [courseId, coursesManyInfoquery]);

  const course = useMemo(() => {
    if (data) {
      return data.coursesManyInfo[0];
    }
    return undefined;
  }, [data]);

  return (
    <EditDataWrapper
      searchKey="id"
      title="Editar curso"
      placeholder="Buscar por id"
    >
      {(loading || loadingConfig) && <Spin />}
      {course && (
        <Space direction="vertical" size="large">
          <Title level={5}>
            {course.title}
          </Title>
          <Row>
            <Col span={24}>
              <Text strong>Cronograma de aulas</Text>
              <Paragraph>
                Nessa seção é possível editar o cronograma das aulas do curso. É importante que alterações em datas
                de liberação sejam feitas antes que a aula seja liberada, pois, após liberação, agendas do usuário
                já criadas que possuirem a aula
                <Text strong> não </Text>
                serão recalculadas. Também é possível editar a fase das aulas.
              </Paragraph>
              <Paragraph>
                Após editar todos os campos, clique no botão SALVAR  no final da tabela para guardar todas as alterações.
              </Paragraph>
              <LessonsTable
                course={course}
                refetch={refetch}
              />
              <ExamsTable
                course={course}
                refetch={refetch}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text strong>Documentos</Text>
              <Paragraph>
                Nesta sessão é possível organizar os documentos que serão exibidos aos alunos do curso, incluindo planners.
              </Paragraph>
              <PlannersTable
                courseId={course._id}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text strong>Semana típica</Text>
              <Paragraph>
                Os valores máximos e mínimos definidos nessa tabela influenciam diretamente
                na configuração da semana típica do usuário na página de perfil da plataforma.
                Esses valores são usados como limites mínimos e máximos de marcação quando o usuário
                edita sua semana típica.
              </Paragraph>
              <Paragraph>
                Os valores mínimos (com exceção do valor mínimo para estudo teórico) são utilizados também como valores de comparação para determinar
                se o usuário atingiu a meta semanal ou não (e, consequentemente, ganhou troféu). Ao alterar
                algum valor, troféus que foram entregues em semanas anteriores com base numa configuração
                anterior de metas mínimas
                <Text strong> não </Text>
                serão recalculados. A configuração passará a valer apenas para as conquistas contabilizadas deste momento em diante.
              </Paragraph>
              <WeekGoalsTable course={course} courseConfig={configData?.courseConfigInfo} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text strong>Metas para troféu</Text>
              <Paragraph>
                Aqui são definidos as quantidades a serem feitas de cada atividade para o aluno conquistar os troféus bronze, prata e ouro.
              </Paragraph>
              <TrophyGoalsTable
                course={course}
                courseConfig={configData?.courseConfigInfo}
                refetchCourseConfig={refetchCourseConfig}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text strong>Atividades extra permitidas</Text>
              <Paragraph>
                Aqui são definidos as atividades extras que os alunos do curso têm acesso.
              </Paragraph>
              <AllowedActivitiesSelector
                activities={configData?.courseConfigInfo.activities}
                refetchCourseConfig={refetchCourseConfig}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text strong>Configurações gerais</Text>
              <Paragraph>
                Aqui são definidas configurações gerais do curso.
              </Paragraph>
              <CourseGeneralInformation
                courseConfig={configData?.courseConfigInfo}
                refetchCourseConfig={refetchCourseConfig}
              />
            </Col>
          </Row>
        </Space>
      )}
    </EditDataWrapper>
  );
}
