import React, { FC, useCallback, useState } from 'react';
import {
  Button, Col, Input, Modal, Row, Typography,
} from 'antd';
import {
  BoldOutlined, ItalicOutlined, LinkOutlined, PictureOutlined, QuestionCircleOutlined, UnorderedListOutlined,
} from '@ant-design/icons';
import { ImageInputModal } from './ImageInputModal.';

const { info } = Modal;
const { TextArea } = Input;
const { Text } = Typography;

type AristoMarkdownEditorProps = {
  body: string;
  onUpdate: (value: string) => void;
  disabled?: boolean;
  disableImage?: boolean;
}

type MarkdownTagTypes = 'h1' | 'h2' | 'bold' | 'italic' | 'link' | 'list';

export const AristoMarkdownEditor: FC<AristoMarkdownEditorProps> = ({
  body,
  onUpdate,
  disabled = false,
  disableImage = false,
}) => {
  const [selectedText, setSelectedText] = useState<string>('');
  const [cursorPosition, setCursorPosition] = useState<{start: number, end: number} | null>(null);
  const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false);

  const handleTextSelection = (e: React.SyntheticEvent<HTMLTextAreaElement, Event>) => {
    setSelectedText('');
    const inputElement = e.target as HTMLInputElement;
    const startIndex = inputElement.selectionStart;
    const endIndex = inputElement.selectionEnd;
    if (startIndex != null && endIndex != null) {
      const selection = inputElement.value.substring(startIndex, endIndex);
      setSelectedText(selection);
      setCursorPosition({ start: startIndex, end: endIndex });
    }
  };

  const handleConvertMarkdown = (tag: MarkdownTagTypes) => {
    if (!selectedText.length) {
      return;
    }

    let markdownText = '';

    switch (tag) {
      case 'h1':
        markdownText = `## ${selectedText}`;
        break;
      case 'h2':
        markdownText = `# ${selectedText}`;
        break;
      case 'bold':
        markdownText = `**${selectedText}**`;
        break;
      case 'italic':
        markdownText = `*${selectedText}*`;
        break;
      case 'link':
        markdownText = `[ADICIONE A DESCRIÇÃO DO LINK AQUI](${selectedText})`;
        break;
      case 'list':
        markdownText = `- ${selectedText}`;
        break;
      default:
        break;
    }

    if (cursorPosition) {
      const { start, end } = cursorPosition;
      const textBefore = body.slice(0, start);
      const textAfter = body.slice(end);
      const newReplyBody = `${textBefore}${markdownText}${textAfter}`;
      onUpdate(newReplyBody);
      onUpdate(newReplyBody);
      setSelectedText('');
      setCursorPosition(null);
    }
  };

  const handleModalInfo = useCallback(() => {
    info({
      title: 'Dicas rápidas de escrita em markdown',
      content: (
        <>
          <p>- Titulo grande: # Titulo</p>
          <p>- Titulo médio: ## Titulo</p>
          <p>- Titulo pequeno: ### Titulo</p>
          <p>- **Negrito**</p>
          <p>- *Itálico*</p>
          <p>- Lista:</p>
          <p>&nbsp;&nbsp;- Item 1</p>
          <p>&nbsp;&nbsp;- Item 2</p>
          <p>- Links: [texto](https://www.site.com)</p>
          <p>- Imagem: ![ ](caminho_da_imagem.jpg)</p>
          <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" rel="noreferrer">
            Referência: https://www.markdownguide.org/cheat-sheet/
          </a>
        </>
      ),
      onOk: () => { },
      width: 1000,
    });
  }, []);

  return (
    <Col
      style={{
        width: '100%',
        margin: '8px',
      }}
    >
      <ImageInputModal
        isOpen={isOpenImageModal}
        onClose={() => setIsOpenImageModal(false)}
        onConfirm={e => onUpdate(body + e)}
      />
      <Row justify="space-between" align="middle">
        <Row>
          <Button
            disabled={disabled}
            type="text"
            style={{ margin: '4px' }}
            icon={<BoldOutlined style={{ color: 'black' }} />}
            onClick={() => handleConvertMarkdown('bold')}
          />
          <Button
            disabled={disabled}
            type="text"
            style={{ margin: '4px' }}
            icon={<ItalicOutlined style={{ color: 'black' }} />}
            onClick={() => handleConvertMarkdown('italic')}
          />
          <Button
            disabled={disabled}
            type="text"
            style={{ margin: '4px' }}
            icon={<LinkOutlined style={{ color: 'black' }} />}
            onClick={() => handleConvertMarkdown('link')}
          />
          <Button
            disabled={disabled}
            type="text"
            style={{ margin: '4px' }}
            icon={<UnorderedListOutlined style={{ color: 'black' }} />}
            onClick={() => handleConvertMarkdown('list')}
          />
          {
            disableImage !== true && (
              <Button
                disabled={disabled}
                type="text"
                style={{ margin: '4px' }}
                icon={<PictureOutlined style={{ color: 'black' }} />}
                onClick={() => setIsOpenImageModal(true)}
              />
            )
          }
          <Button
            disabled={disabled}
            type="text"
            style={{ margin: '4px' }}
            onClick={() => handleConvertMarkdown('h1')}
          >
            <Text style={{ fontWeight: 'bold' }}>H1</Text>
          </Button>
          <Button
            disabled={disabled}
            type="text"
            style={{ margin: '4px' }}
            onClick={() => handleConvertMarkdown('h2')}
          >
            <Text style={{ fontWeight: 'bold' }}>H2</Text>
          </Button>
        </Row>
        <Button
          type="text"
          icon={<QuestionCircleOutlined />}
          onClick={handleModalInfo}
        />
      </Row>
      <TextArea
        id="text-area"
        style={{
          borderRadius: '8px',
          minHeight: '300px',
          padding: '16px',
          backgroundColor: disabled ? '#C4C6D0' : 'white',
        }}
        disabled={disabled}
        value={body}
        onSelect={handleTextSelection}
        onChange={e => onUpdate(e.target.value)}
      />
    </Col>
  );
};
