import React, { CSSProperties, FC, useMemo } from 'react';
import {
  Col, Collapse, Row, Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Profile } from '../../../api/profile';
import { useCoursesProfile, useTrialInfo } from '../../../api/courses';
import { formatRelativeDate } from '../../../utils/formatRelativeDate';
import { CardOfCourse } from './CardOfCourse';

interface RowTableProps {
  label: string | React.ReactChild;
  isStrong?: boolean;
  span?: number;
  style?: CSSProperties
}

const { Text } = Typography;

const RowTable: FC<RowTableProps> = ({
  label, isStrong, span = 6, style,
}) => (
  <Col span={span}>
    <Text strong={isStrong}>
      {label}
    </Text>
  </Col>
);

export const TableOfUserCourses: FC<{ cardTitle: string, profile: Profile }> = ({
  cardTitle,
  profile,

}) => {
  const { data, loading } = useCoursesProfile(profile.email);
  const { Panel } = Collapse;

  const courses = useMemo(() => {
    if (!data?.infoCourseProfile) {
      return [];
    }

    const spread = [...data.infoCourseProfile];

    return spread.sort((a, b) => {
      const dateA = a.acquisitionDate ? new Date(a.acquisitionDate) : null;
      const dateB = b.acquisitionDate ? new Date(b.acquisitionDate) : null;

      if (a.status === 'inativo' && b.status === 'inativo') {
        return (dateB?.getTime() || 0) - (dateA?.getTime() || 0);
      }

      if (a.status === 'inativo') { return 1; }
      if (b.status === 'inativo') { return -1; }

      return (dateB?.getTime() || 0) - (dateA?.getTime() || 0);
    });
  }, [data?.infoCourseProfile]);

  return (
    <div
      style={{
        marginTop: '18px',
        marginBottom: '18px',
        padding: '0 16px',
        borderRadius: '8px',
        background: '#fafafa',
      }}
    >
      <Typography
        style={{
          fontSize: '28px',
          fontWeight: 400,
          padding: '18px 0px',
        }}
      >
        {cardTitle}
      </Typography>
      <Row style={{ paddingLeft: '12px', paddingRight: '17px' }}>
        <RowTable isStrong label="Cursos Adiquiridos" span={10} />
        <RowTable isStrong label="Status" />
        <RowTable isStrong label="Data" />
        <RowTable isStrong label="Ações" span={1} />
      </Row>
      <Collapse
        defaultActiveKey={['course-info']}
        bordered={false}

      >
        {courses.map(it => {
          return (
            <Panel
              key={`courses ${it.courseId}}`}
              header={(
                <Row>
                  <RowTable label={it.name} span={10} />
                  <RowTable label={it.status} />
                  <RowTable label={formatRelativeDate(new Date(it.acquisitionDate), true)} />
                  <RowTable label={<SearchOutlined />} span={1} />
                </Row>
              )}
              showArrow={false}
            >
              <CardOfCourse
                cardTitle={`Informações do curso ${it.name}`}
                courseId={it.courseId}
                email={profile.email}
              />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
