import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import {
  Dropdown, MenuProps, Modal, Row, Space,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { LoadingOutlined } from '@ant-design/icons';
import { ExamBookletObjectType, useDeleteExamBooklet, useEditExamBooklet } from '../../../../api/appeal';
import CreateBooklistForm from './CreateBooklistForm';
import { GlobalContext } from '../../../../contexts/Global.context';

const { confirm } = Modal;
const { Text } = Typography;

type BookletItemProps = {
  data: ExamBookletObjectType,
  onDelete: () => void;
}

const BookletItem: FC<BookletItemProps> = ({
  data,
  onDelete,
}) => {
  const [state, setState] = useState(data);
  const [editMode, setEditMode] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [del] = useDeleteExamBooklet();
  const [edit] = useEditExamBooklet();

  const {
    profile,
  } = useContext(GlobalContext);

  const isAdmin = useMemo(() => {
    return profile.data?.profile.roles?.includes('admin') || false;
  }, [profile.data?.profile.roles]);

  const isSuperAdmin = useMemo(() => {
    return profile.data?.profile.roles?.includes('super-admin') || false;
  }, [profile.data?.profile.roles]);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    try {
      await del({
        variables: {
          examBookleId: data._id,
        },
      });
      onDelete();
    } catch (_) {
      console.log('Error');
    } finally {
      setDeleting(false);
    }
  }, [data._id, del, onDelete]);

  const handleEdit = useCallback(async (v: ExamBookletObjectType) => {
    if (state.examVersion === v.examVersion
      && state.name === v.name
      && state.totalQuestions === v.totalQuestions
      && state.year === v.year
      && state.dateLimitToCreateAppeal === v.dateLimitToCreateAppeal
    ) {
      return;
    }
    try {
      await edit({
        variables: {
          examBookletId: v._id,
          editExamBookletInput: {
            examVersion: state.examVersion === v.examVersion ? undefined : v.examVersion,
            name: state.name === v.name ? undefined : v.name,
            totalQuestions: state.totalQuestions === v.totalQuestions ? undefined : v.totalQuestions,
            year: state.year === v.year ? undefined : v.year,
            dateLimitToCreateAppeal: state.dateLimitToCreateAppeal === v.dateLimitToCreateAppeal ? undefined : v.dateLimitToCreateAppeal,
          },
        },
      });

      setState(v);
    } catch (e) {
      console.log(e);
    }
  }, [edit, state.dateLimitToCreateAppeal, state.examVersion, state.name, state.totalQuestions, state.year]);

  const items: MenuProps['items'] = useMemo(() => [
    {
      key: '1',
      label: (
        <Row
          style={{ margin: '8px', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faEdit} style={{ marginRight: '8px' }} />
          <Text>Editar</Text>
        </Row>
      ),
      onClick: () => setEditMode(true),
    }, ...[isSuperAdmin ? {
      key: '2',
      label: deleting ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Row
          style={{ margin: '8px', alignItems: 'center' }}
        >
          <>
            <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '8px' }} color="#AB2D25" />
            <Text style={{ color: '#AB2D25' }}>Excluir</Text>
          </>
        </Row>
      ),
      onClick: () => confirm({
        title: 'Excluir prova',
        content: 'Você tem certeza que deseja excluir essa prova? Essa ação é irreversível.',
        okText: 'Excluir',
        icon: null,
        onOk: handleDelete,
        onCancel: () => {},
        cancelText: 'Cancelar',
      }),
    } : null],
  ], [deleting, handleDelete, isSuperAdmin]);

  if (editMode) {
    return (
      <CreateBooklistForm
        onClose={() => setEditMode(false)}
        value={state}
        onConfirm={handleEdit}
      />
    );
  }

  return (
    <Row align="middle">
      <Space style={{ padding: '0 8px 0 16px', flex: 1 }}>
        {state.institutionTarget.uf}
      </Space>
      <Space style={{ padding: '0px 8px', flex: 4 }}>
        {state.institutionTarget.institutionName}
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1 }}>
        {state.year}
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1 }}>
        {state.totalQuestions}
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1 }}>
        {state.examVersion}
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1 }}>
        {moment(state.dateLimitToCreateAppeal).format('DD/MM/YYYY HH:mm')}
      </Space>
      <Space style={{ padding: '0px 8px', flex: 2 }}>
        {state.name}
      </Space>
      <Space
        style={{
          padding: '0px 16px 0 8px',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        {
          isAdmin ? (
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              overlayStyle={{ background: '#EDEDF4' }}
            >
              <FontAwesomeIcon icon={faEllipsisV} size="lg" style={{ cursor: 'pointer' }} />
            </Dropdown>
          ) : <div />
        }
      </Space>
    </Row>
  );
};

export default BookletItem;
