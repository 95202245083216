import {
  gql, useMutation, useQuery, QueryResult, MutationFunction,
} from '@apollo/client';

const CREATE_APPEAL_BY_ADMIN = gql`
  mutation CreateAppealByAdmin($input: AppealCreatedByAdminInputType!) {
    createAppealByAdmin(input: $input)
  }
`;

const EDIT_RESPONDED_APPEAL = gql`
  mutation EditRespondedAppeal($appealId: ObjectId!, $editRespondedAppealInput: EditRespondedAppealInputType!) {
    editRespondedAppeal(appealId: $appealId, editRespondedAppealInput: $editRespondedAppealInput)
  }
`;

const GET_APPEAL_TO_RESPOND = gql`
  query GetAppealToRespond($appealId: ObjectId!) {
    getAppealToRespond(appealId: $appealId) {
      _id
      profile
      institution
      examVersion
      examBooklet
      question {
        majorArea
        questionNumber
        description
        questionPrintsUrls
      }
      appealEndDate
      moderator {
        moderatorId
        moderatorResponse
        reservedUntil
      }
      status
    }
  }
`;

const RESPOND_APPEAL = gql`
  mutation RespondAppeal($appealId: ObjectId!, $moderatorResponse: String!, $isAppealAccepted: Boolean!) {
    respondAppeal(appealId: $appealId, moderatorResponse: $moderatorResponse, isAppealAccepted: $isAppealAccepted)
  }
`;

const CANCEL_APPEAL = gql`
  mutation CancelAppeal($appealId: ObjectId!, $reasonForCancellation: String!) {
    cancelAppeal(appealId: $appealId, reasonForCancellation: $reasonForCancellation)
  }
`;

const FINALIZE_APPEAL = gql`
  mutation FinalizeAppeal($appealId: ObjectId!) {
    finalizeAppeal(appealId: $appealId)
  }
`;

const FIND_MANY_APPEALS = gql`
  query FindManyAppeals($appealFilter: AppealFilterInputType, $skip: Int, $limit: Int) {
    findManyAppeals(appealFilter: $appealFilter, skip: $skip, limit: $limit) {
      appeals {
        _id
        profile
        institution
        examVersion
        question {
          majorArea
          questionNumber
          description
          questionPrintsUrls
        }
        appealEndDate
        createdAt
        year
        moderator {
          moderatorId
          moderatorResponse
        }
        status
      }
      totalAppeals
    }
  }
`;

const ALL_INSTITUTIONS = gql`
  query AllInstitutions($institutionFilter: String, $skip: Int = 0, $limit: Int = 100) {
    allInstitutions(institutionFilter: $institutionFilter, skip: $skip, limit: $limit) {
      institutions {
        _id
        title
        institution
        uf
        appealsCount
        appeals {
          year
          appealId
        }
      }
      totalInstitutions
    }
  }
`;

const APPEAL_FINISHED_LIST = gql`
  query AppealFinishedList($appealsId: [ObjectId!]!) {
    appealFinishedList(appealsId: $appealsId) {
      appealId
      questionNumber
      examVersion
      isAppealAccepted
      moderatorResponse
      createdAt
      examBooklet
    }
  }
`;

const UNLOCK_APPEAL = gql`
  mutation UnlockAppeal($appealId: ObjectId!) {
    unlockAppeal(appealId: $appealId)
  }
`;

const MAJOR_AREA_FILTER = gql`
  query MajorAreaFilter {
    majorAreaFilter
  }
`;

const LIST_EXAM_BOOKLETS = gql`
  query ListExamBooklets($institutionTarget: InstitutionTargetInputType, $page: Int = 0, $perPage: Int = 100) {
    listExamBooklets(institutionTarget: $institutionTarget, page: $page, perPage: $perPage) {
      _id
      institutionTarget {
        institutionName
        uf
      }
      year
      name
      totalQuestions
      examVersion
      dateLimitToCreateAppeal
    }
  }
`;

const DELETE_EXAM_BOOKLET = gql`
  mutation DeleteExamBooklet($examBookleId: ObjectId!) {
    deleteExamBooklet(examBookleId: $examBookleId)
  }
`;

const EDIT_EXAM_BOOKLET = gql`
  mutation EditExamBooklet($examBookletId: ObjectId!, $editExamBookletInput: EditExamBookletInput!) {
    editExamBooklet(examBookletId: $examBookletId, editExamBookletInput: $editExamBookletInput)
  }
`;

const CREATE_EXAM_BOOKLET = gql`
  mutation CreateExamBooklet($input: ExamBookletInputType!) {
    createExamBooklet(input: $input)
  }
`;

export interface EditExamBookletInput {
  year?: number;
  name?: string;
  totalQuestions?: number;
  examVersion?: string;
  dateLimitToCreateAppeal?: string;
}

export interface ExamBookletObjectType {
  _id: string;
  institutionTarget: InstitutionTarget;
  year: number;
  name: string;
  totalQuestions: number;
  examVersion: string;
  dateLimitToCreateAppeal: string;
}

interface ExamBookletInputType {
  year: number;
  name: string;
  totalQuestions: number;
  institution: InstitutionTarget;
  examVersion?: string;
  dateLimitToCreateAppeal: string;
}

interface InstitutionTarget {
  institutionName: string;
  uf: string;
}

interface AppealCreatedByAdminInputType {
  questionNumber: number;
  response: string;
  isAppealAccepted: boolean;
  majorArea: string;
  examVersion: string;
  institution: InstitutionTarget;
  year: number;
}

interface EditRespondedAppealInputType {
  response?: string;
  isAppealAccepted?: boolean;
}

interface QuestionAppeal {
  majorArea: string;
  questionNumber: number;
  description?: string;
  questionPrintsUrls?: string[];
}

interface Moderator {
  moderatorId: string;
  moderatorResponse?: string;
  reservedUntil?: Date;
}

export interface AppealObjectType {
  _id: string;
  profile: string;
  institution: string;
  examVersion: string;
  examBooklet: string;
  question: QuestionAppeal;
  appealEndDate?: Date;
  year: number;
  moderator?: Moderator;
  createdAt: Date;
  status?: 'pending' | 'canceled' | 'review' | 'finished';
}

export interface FindManyAppealObjectType {
  appeals: AppealObjectType[];
  totalAppeals: number;
}

interface AppealFilterInputType {
  majorArea?: string;
  email?: string;
  institution?: InstitutionTarget;
  deadlineDate?: Date;
  status?: string;
  sortedByDate?: number;
}

interface GroupedInstitutionAppealData {
  year: number;
  appealId: string[];
}

export interface GroupedInstitutionAppealDataObjectType {
  _id: string;
  title: string;
  institution: string;
  uf: string;
  appealsCount: number;
  appeals: GroupedInstitutionAppealData[];
}

export interface ListAllInstitutionsObjectType {
  institutions: GroupedInstitutionAppealDataObjectType[];
  totalInstitutions: number;
}

export interface GetAppealsFinishedObjectType {
  appealId: string;
  questionNumber: number;
  examVersion: string;
  isAppealAccepted: boolean;
  moderatorResponse: string;
  createdAt: Date;
  examBooklet: string;
}

export function useListExamBooklets(
  institutionTarget?: InstitutionTarget, page?: number, perPage?: number,
): QueryResult<{ listExamBooklets: ExamBookletObjectType[] }> {
  return useQuery<{ listExamBooklets: ExamBookletObjectType[] }>(LIST_EXAM_BOOKLETS, {
    variables: { institutionTarget, page, perPage },
    fetchPolicy: 'no-cache',
  });
}

export function useDeleteExamBooklet(): [
  MutationFunction<any, { examBookleId: string }>, { data?: boolean, loading: boolean, error?: any },
  ] {
  return useMutation(DELETE_EXAM_BOOKLET);
}

export function useEditExamBooklet(): [
  MutationFunction<any, { examBookletId: string, editExamBookletInput: EditExamBookletInput }>, { data?: boolean, loading: boolean, error?: any },
  ] {
  return useMutation(EDIT_EXAM_BOOKLET);
}

export function useCreateExamBooklet(): [
  MutationFunction<any, { input: ExamBookletInputType }>, { data?: boolean, loading: boolean, error?: any },
  ] {
  return useMutation(CREATE_EXAM_BOOKLET);
}

export function useCreateAppealByAdmin(): [
  MutationFunction<any, { input: AppealCreatedByAdminInputType }>, { data?: any, loading: boolean, error?: any },
  ] {
  return useMutation(CREATE_APPEAL_BY_ADMIN);
}

export function useEditRespondedAppeal(): [
  MutationFunction<any, { appealId: string, editRespondedAppealInput: EditRespondedAppealInputType }>, { data?: any, loading: boolean, error?: any },
  ] {
  return useMutation(EDIT_RESPONDED_APPEAL);
}

export function useGetAppealToRespond(appealId: string): QueryResult<{ getAppealToRespond: AppealObjectType }> {
  return useQuery<{ getAppealToRespond: AppealObjectType }>(GET_APPEAL_TO_RESPOND, {
    variables: { appealId },
    fetchPolicy: 'no-cache',
  });
}

export function useRespondAppeal(): [
  MutationFunction<any, { appealId: string, moderatorResponse: string, isAppealAccepted: boolean }>, { data?: any, loading: boolean, error?: any },
  ] {
  return useMutation(RESPOND_APPEAL);
}

export function useCancelAppeal(): [
  MutationFunction<any, { appealId: string, reasonForCancellation: string }>, { data?: any, loading: boolean, error?: any },
  ] {
  return useMutation(CANCEL_APPEAL);
}

export function useFinalizeAppeal(): [
  MutationFunction<any, { appealId: string }>, { data?: any, loading: boolean, error?: any },
  ] {
  return useMutation(FINALIZE_APPEAL);
}

export function useFindManyAppeals(
  appealFilter?: AppealFilterInputType, skip?: number, limit?: number,
): QueryResult<{ findManyAppeals: FindManyAppealObjectType }> {
  return useQuery<{ findManyAppeals: FindManyAppealObjectType }>(FIND_MANY_APPEALS, {
    variables: { appealFilter, skip, limit },
    fetchPolicy: 'no-cache',
  });
}

export function useAllInstitutions(
  institutionFilter?: string, skip: number = 0, limit: number = 100,
): QueryResult<{allInstitutions: ListAllInstitutionsObjectType}> {
  return useQuery<{allInstitutions: ListAllInstitutionsObjectType}>(ALL_INSTITUTIONS, {
    variables: { institutionFilter, skip, limit },
    fetchPolicy: 'no-cache',
  });
}

export function useAppealFinishedList(appealsId: string[]): QueryResult<{
  appealFinishedList: GetAppealsFinishedObjectType[],
}> {
  return useQuery<{ appealFinishedList: GetAppealsFinishedObjectType[] }>(APPEAL_FINISHED_LIST, {
    variables: { appealsId },
    fetchPolicy: 'no-cache',
  });
}

export function useUnlockAppeal(): [
  MutationFunction<any, {appealId: string}>, { data?: any, loading: boolean, error?: any },
  ] {
  return useMutation(UNLOCK_APPEAL);
}

export function useMajorAreaFilter(): QueryResult<{ majorAreaFilter: string[] }> {
  return useQuery<{ majorAreaFilter: string[] }>(MAJOR_AREA_FILTER);
}
