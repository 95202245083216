import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  Button,
  Row,
  Select,
  Input,
  Space,
  InputNumber,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ExamBookletObjectType } from '../../../../api/appeal';
import { useGetInstitutionList } from '../../../../api/institutions';
import { getNameBetweenParenthesis } from '../getNameBetweenParenthesis';

type CreateBooklistFormParams = {
  onClose: () => void;
  value?: ExamBookletObjectType;
  onConfirm: (v: ExamBookletObjectType) => Promise<void>;
}

const CreateBooklistForm: FC<CreateBooklistFormParams> = ({
  onClose, value, onConfirm,
}) => {
  const [institution, setInstitution] = useState<string | undefined>(value?.institutionTarget.institutionName);
  const [uf, setUf] = useState<string | undefined>(value?.institutionTarget.uf);
  const [year, setYear] = useState<number | undefined>(value?.year);
  const [totalQuestions, setTotalQuestions] = useState<number | undefined>(value?.totalQuestions);
  const [examVersion, setExamVersion] = useState<string | undefined>(value?.examVersion);
  const [name, setName] = useState<string | undefined>(value?.name);
  const [dateLimitToCreateAppeal, setDateLimitToCreateAppeal] = useState<string | undefined>(value?.dateLimitToCreateAppeal);

  const { data, loading } = useGetInstitutionList();

  const ufList = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    const result = [...data.getInstitutionListAdmin];
    result.sort((a, b) => {
      const ufA = a.uf.toUpperCase();
      const ufB = b.uf.toUpperCase();
      if (ufA < ufB) {
        return -1;
      }
      if (ufA > ufB) {
        return 1;
      }
      return 0;
    });
    return result;
  }, [data, loading]);

  const institutionList = useMemo(() => {
    const inst = ufList?.find(ufItem => ufItem.uf === uf)?.institutions ?? [];
    const result = [...inst];
    return result.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }, [uf, ufList]);

  const ufOptions = useMemo(() => {
    return ufList?.map(e => ({ value: e.uf, label: e.uf })) || [];
  }, [ufList]);

  const institutionOptions = useMemo(() => {
    return institutionList?.map(e => {
      const v = getNameBetweenParenthesis(e.name);
      return ({ value: v ?? '', label: e.name });
    }) || [];
  }, [institutionList]);

  const handleUf = useCallback((v: string) => {
    setInstitution(undefined);
    setUf(v);
  }, []);

  const handleInstitution = useCallback((v: string) => {
    setInstitution(v);
  }, []);

  const handleYear = useCallback((v: number) => {
    setYear(v);
  }, []);

  const handleNumberOfQuestions = useCallback((v: number | null) => {
    if (v === null) {
      setTotalQuestions(undefined);
      return;
    }
    setTotalQuestions(v);
  }, []);

  const handleExamVersion = useCallback((v: string) => {
    setExamVersion(v);
  }, []);

  const handleDateLimitToCreateAppeal = useCallback((v: moment.Moment | null, dateString: string) => {
    if (!v) {
      return;
    }
    setDateLimitToCreateAppeal(v.toISOString());
  }, []);

  const handleName = useCallback((v: string) => {
    setName(v);
  }, []);

  const confirmDisabled = useMemo(() => {
    return !examVersion || !institution || !uf || !name || !totalQuestions || !year || !dateLimitToCreateAppeal;
  }, [dateLimitToCreateAppeal, examVersion, institution, name, totalQuestions, uf, year]);

  const handleConfirm = useCallback(async () => {
    if (!examVersion || !institution || !uf || !name || !totalQuestions || !year || !dateLimitToCreateAppeal) {
      return;
    }
    await onConfirm({
      _id: value?._id || '',
      examVersion,
      institutionTarget: {
        institutionName: institution,
        uf,
      },
      name,
      totalQuestions,
      year,
      dateLimitToCreateAppeal,
    });
    onClose();
  }, [dateLimitToCreateAppeal, examVersion, institution, name, onClose, onConfirm, totalQuestions, uf, value?._id, year]);

  return (
    <Row style={{ fontWeight: 'bold' }} className="select-full" align="middle">
      <Space style={{ padding: '0px 8px 0 16px', flex: 1, overflow: 'hidden' }}>
        {
          value === undefined
            ? (
              <Select
                placeholder="UF"
                options={ufOptions}
                value={uf}
                onChange={handleUf}
              />
            ) : value!.institutionTarget.uf
        }
      </Space>
      <Space style={{ padding: '0px 8px', flex: 4, overflow: 'hidden' }}>
        {
          value === undefined
            ? (
              <Select
                placeholder="Instituição"
                options={institutionOptions}
                value={institution}
                onChange={handleInstitution}
              />
            ) : value!.institutionTarget.institutionName
        }
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1, overflow: 'hidden' }}>
        <Select
          placeholder="Ano"
          options={Array.from({ length: 3 }, (_, i) => new Date().getFullYear() - 1 + i).map(e => ({ value: e, label: e }))}
          value={year}
          onChange={handleYear}
        />
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1, overflow: 'hidden' }}>
        <InputNumber<number>
          placeholder="Quantidade"
          min={0}
          value={totalQuestions}
          onChange={handleNumberOfQuestions}
        />
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1, overflow: 'hidden' }}>
        <Select
          options={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
          ]}
          placeholder="Edição"
          value={examVersion}
          onChange={handleExamVersion}
        />
      </Space>
      <Space style={{ padding: '0px 8px', flex: 1, overflow: 'hidden' }}>
        <DatePicker
          format="DD/MM/YYYY HH:mm"
          placeholder="Selecione a data limite"
          value={dateLimitToCreateAppeal ? moment(dateLimitToCreateAppeal) : undefined}
          allowClear={false}
          showTime
          onChange={handleDateLimitToCreateAppeal}
        />
      </Space>
      <Space style={{ padding: '0px 8px', flex: 2, overflow: 'hidden' }}>
        <Input
          placeholder="Nome"
          value={name}
          onChange={e => handleName(e.target.value)}
        />
      </Space>
      <Space style={{ padding: '0px 16px 0 8px', flex: 1 }}>
        <Button
          shape="circle"
          type="primary"
          icon={<CloseOutlined />}
          onClick={onClose}
          style={{ background: '#F9DBD7', border: 'none', color: '#3B0605' }}
        />
        <Button
          shape="circle"
          type="primary"
          icon={<CheckOutlined />}
          disabled={confirmDisabled}
          onClick={handleConfirm}
          style={{ background: '#D9E3FD', border: 'none', color: '#3B0605' }}
        />
      </Space>
    </Row>
  );
};

export default CreateBooklistForm;
