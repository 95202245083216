import { FC } from 'react';
import { Profile } from '../../api/profile';
import { TableUserInfo } from './components/TableUserInfo';
import { TableOfUserCourses } from './components/TableOfUserCourses';

const sectionTitleStyle = {
  fontSize: '28px',
  fontWeight: 400,
  margin: '20px 0px',
};

export const GeneralUserInfoDashbord: FC<{ profile: Profile }> = ({
  profile,
}) => {
  return (
    <>
      <TableUserInfo cardTitle={profile.name} userEmail={profile.email} />
      <TableOfUserCourses cardTitle="Cursos" profile={profile} />
    </>
  );
};
