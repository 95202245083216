import { Col } from 'antd';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import { CourseContextProvider } from '../contexts/Courses.context';
import Forbidden from './403';
import { EditCoursePage } from './courses/EditCoursePage';
import { ForumMainPage } from './forum/ForumMainPage';
import { CoursesPage } from './courses/CoursesPage';
import DashboardPage from './dashboard/DashboardPage';
import EditFlashcardPage from './flashcards/EditFlashcardPage';
import { FlashcardsPage } from './flashcards/FlashcardsPage';
import { LessonsPage } from './lessons/LessonsPage';
import EditMindmapPage from './mindmaps/EditMindmapPage';
import { MindmapsPage } from './mindmaps/MindmapsPage';
import EditQuestionPage from './questions/EditQuestionPage';
import EditTextPage from './texts/EditTextPage';
import { TextsPage } from './texts/TextsPage';
import EditUserPage from './users/EditUserPage';
import EditVideoPage from './videos/EditVideoPage';
import { VideosPage } from './videos/VideosPage';
import { QuestionsPage } from './questions/QuestionsPage';
import { EditLessonPage } from './lessons/EditLessonPage';
import { AristoPlusPDFsPage } from './aristo-plus/PDFsPage';
import { AristoPlusVideosPage } from './aristo-plus/VideosPage';
import { BurgerMenus } from './burger-menu/BurgerMenus';
import { EditBurgerMenu } from './burger-menu/EditBurgerMenu';
import { LessonQuestionsPage } from './lesson-questions/LessonQuestionsPage';
import { AddQuestionPage } from './lesson-questions/AddQuestionPage';
import { BroadcastNotifications } from './notifications/BroadcastNotifications';
import { EditBroadcastNotification } from './notifications/EditBroadcastNotification';
import { EditPdfPage } from './pdfs/EditPdfPage';
import { ForumCommentPage } from './forum/ForumCommentPage';
import UserDashboardPage from './user-dashboard/UserDashboardPage';
import AppealsFlowPage from './resources/resources-flow/AppealsFlowPage';
import AppealsBankPage from './resources/bank-flow.tsx/AppealsBankPage';
import { AppealCommentCreation } from './resources/bank-flow.tsx/AppealCommentCreation';
import ExamsManagerPage from './resources/exams-manager/ExamsManagerPage';

export function PagesRoutes() {
  return (
    <Col span={24}>
      <CourseContextProvider>
        <Switch>
          <PrivateRoute exact path="/admin">
            <Redirect to="admin/dashboard" />
          </PrivateRoute>
          <PrivateRoute path="/admin/dashboard" component={DashboardPage} />
          <PrivateRoute path="/admin/forum" component={ForumMainPage} clearences={['admin', 'moderator']} />
          <PrivateRoute path="/admin/responder-comentario" component={ForumCommentPage} clearences={['admin', 'moderator']} />
          <PrivateRoute path="/admin/configuracao/burger-menus" component={BurgerMenus} clearences={['admin']} />
          <PrivateRoute path="/admin/configuracao/editar-burger-menu" component={EditBurgerMenu} clearences={['admin']} />
          <PrivateRoute path="/admin/configuracao/notificacoes" component={BroadcastNotifications} clearences={['admin']} />
          <PrivateRoute path="/admin/configuracao/editar-notificacao" component={EditBroadcastNotification} clearences={['admin']} />
          <PrivateRoute path="/admin/editar-usuarios" component={EditUserPage} clearences={['admin']} />
          <PrivateRoute path="/admin/alunos" component={UserDashboardPage} clearences={['admin', 'curator']} />
          <PrivateRoute path="/admin/cursos" component={CoursesPage} clearences={['admin', 'curator']} />
          <PrivateRoute path="/admin/editar-curso" component={EditCoursePage} clearences={['admin']} />
          <PrivateRoute path="/admin/aulas" component={LessonsPage} clearences={['admin', 'curator']} />
          <PrivateRoute path="/admin/editar-aula" component={EditLessonPage} clearences={['admin', 'curator']} />
          <PrivateRoute path="/admin/aristo-plus/videos" component={AristoPlusVideosPage} clearences={['curator', 'admin']} />
          <PrivateRoute path="/admin/aristo-plus/pdfs" component={AristoPlusPDFsPage} clearences={['curator', 'admin']} />
          <PrivateRoute path="/admin/editar-pdf" component={EditPdfPage} clearences={['admin', 'curator']} />
          <PrivateRoute path="/admin/questoes" component={QuestionsPage} clearences={['commentator', 'admin']} />
          <PrivateRoute path="/admin/questoes-aula" component={LessonQuestionsPage} clearences={['curator', 'admin']} />
          <PrivateRoute path="/admin/editar-questao" component={EditQuestionPage} clearences={['commentator', 'admin']} />
          <PrivateRoute path="/admin/flashcards" component={FlashcardsPage} clearences={['commentator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/editar-flashcard" component={EditFlashcardPage} clearences={['commentator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/mindmaps" component={MindmapsPage} clearences={['curator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/editar-mindmap" component={EditMindmapPage} clearences={['commentator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/apostilas" component={TextsPage} clearences={['commentator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/editar-apostila" component={EditTextPage} clearences={['commentator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/videos" component={VideosPage} clearences={['commentator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/editar-video" component={EditVideoPage} clearences={['curator', 'admin', 'curator']} />
          <PrivateRoute path="/admin/forbiden" component={Forbidden} />
          <PrivateRoute path="/admin/resources/bank" component={AppealsBankPage} clearences={['admin', 'moderator']} />
          <PrivateRoute path="/admin/resources/appeals" component={AppealsFlowPage} clearences={['admin', 'moderator']} />
          <PrivateRoute path="/admin/resources/exams" component={ExamsManagerPage} clearences={['admin', 'moderator']} />
          <PrivateRoute path="/admin/resources/new-appeal" component={AppealCommentCreation} clearences={['admin', 'moderator']} />
        </Switch>
      </CourseContextProvider>
    </Col>
  );
}
