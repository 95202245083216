import { FC, useState } from 'react';
import { Input, Modal } from 'antd';

type ImageInputModalProps = {
    onConfirm: (value : string) => void;
    isOpen: boolean;
    onClose: () => void;
}

export const ImageInputModal : FC<ImageInputModalProps> = ({
  onConfirm,
  isOpen,
  onClose,
}) => {
  const [replyLink, setReplyImageLink] = useState<string>('');
  return (
    <Modal
      title="Insira o Link da Imagem"
      onOk={() => {
        onConfirm(`\n\n![ ](${replyLink})`);
        setReplyImageLink('');
        onClose();
      }}
      open={isOpen}
      onCancel={onClose}
      okText="Inserir"
      width={400}
    >
      <Input
        placeholder="Link da Imagem"
        value={replyLink}
        onChange={e => setReplyImageLink(e.target.value.replaceAll(')', '%29').replaceAll('(', '%28'))}
      />
    </Modal>
  );
};
