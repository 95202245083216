import { FC, useMemo, useState } from 'react';
import {
  Button, Divider, message, Pagination, Row, Space,
  Typography,
} from 'antd';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { AppealListCard } from './components/AppealListCard';
import { PeriodFilter } from './components/filters/PeriodFilter';
import { StatusFilter } from './components/filters/StatusFilter';
import { useFindManyAppeals } from '../../../api/appeal';
import { MajorAreaFilter } from './components/filters/MajorAreaFilter';

const cardStyle = {
  padding: '16px',
  borderRadius: '16px',
  margin: '16px 0px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  background: '#F9F9FF',
};

const AppealsList: FC = () => {
  const [orderFilter, setOrderFilter] = useState(-1);
  const [statusFilter, setStatusFilter] = useState<string | undefined>('pending');
  const [majorAreaFilter, setMajorAreaFilter] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>();

  const {
    data, loading, refetch,
  } = useFindManyAppeals({
    sortedByDate: orderFilter,
    status: statusFilter,
    majorArea: majorAreaFilter,
  }, currentPage - 1, 30);

  const appealsList = useMemo(() => {
    const a = data?.findManyAppeals;
    if (a) {
      setTotal(a.totalAppeals);
    }
    return a;
  }, [data]);

  const handleOrder = (e: number): void => {
    setOrderFilter(e);
  };

  const handleStatus = (e?: string): void => {
    setStatusFilter(e);
  };

  const handleMajorArea = (e?: string): void => {
    setMajorAreaFilter(e);
  };

  const refreshList = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await refetch();
    } catch (err) {
      message.error('Failed to refresh the list');
    }
    setIsLoading(false);
  };

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0px auto' }}>
      <Row justify="space-between">
        <Space wrap>
          <PeriodFilter value={orderFilter} handleChange={handleOrder} />
          <StatusFilter value={statusFilter} handleChange={handleStatus} />
          <MajorAreaFilter value={majorAreaFilter} handleChange={handleMajorArea} />
        </Space>
        <Button loading={loading} onClick={refreshList} icon={<RedoOutlined />}>Atualizar</Button>
      </Row>
      {(loading || isLoading) && (
        <div style={cardStyle}>
          {Array.from({ length: 5 }).map((it, index) => {
            return (
              <>
                <div style={{
                  height: '140px',
                  width: '100%',
                  borderRadius: '16px',
                  backgroundColor: '#F0F0F0',
                }}
                />
                {index < 5 && <Divider style={{ height: '2px' }} />}
              </>
            );
          })}
        </div>
      )}
      {!loading && !isLoading && appealsList && (
      <div style={cardStyle}>
        {appealsList.appeals.length ? (
          appealsList.appeals.map((it, index) => {
            return (
              <>
                <AppealListCard key={it._id} data={it} year={it.year} />
                {index < (appealsList.appeals.length - 1) && (
                <Divider style={{ height: '2px' }} />
                )}
              </>
            );
          })
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography>Não foram encontrados recursos para os filtros selecionados</Typography>
          </div>
        )}
      </div>
      )}
      <Pagination
        style={{ margin: '16px 4px' }}
        simple
        pageSize={21}
        current={currentPage}
        total={total}
        onChange={e => setCurrentPage(e)}
      />
    </div>
  );
};

export default AppealsList;
