import { PoweroffOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Space,
  Typography,
  Button,
  Image,
} from 'antd';
import { useContext, useMemo } from 'react';
import { GlobalContext } from '../contexts/Global.context';
import useAccount from '../hooks/useAccount';
import NavigationMenu from './NavigationMenu';

const { Header } = Layout;

export function HeaderComponent() {
  const { profile } = useContext(GlobalContext);
  const { logout } = useAccount();

  const profileData = useMemo(() => {
    if (profile.data) {
      return profile.data.profile;
    }
    return undefined;
  }, [profile.data]);

  return (
    <Header style={{ padding: '0 3rem', height: 60, overflow: 'hidden' }}>
      <Row align="top" style={{ flexWrap: 'nowrap' }}>
        <Link to="/admin" style={{ marginRight: 16 }}>
          <Image
            src="/images/logo-aristo.svg"
            style={{ height: '2rem', width: '2rem' }}
            preview={false}
          />
        </Link>
        <Col style={{ flex: 1 }}>
          <Row align="top" justify="space-between" style={{ flexWrap: 'nowrap' }}>
            <NavigationMenu />
            <Col>
              <Space>
                {profileData && (
                  <Typography.Text style={{ fontSize: '1rem', color: '#FFFFFF', paddingLeft: '8px' }}>
                    {`Olá, ${profileData.name}`}
                  </Typography.Text>
                )}
                <Button
                  ghost
                  icon={<PoweroffOutlined />}
                  onClick={() => logout()}
                >
                  Sair
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
}
