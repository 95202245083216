/* eslint-disable max-len */
import { FC, useCallback, useState } from 'react';
import {
  Button, Col, Input, Modal, Row, Space, Typography,
} from 'antd';
import { FlagOutlined, MenuOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Markdown from '../../../../components/Markdown';
import { GetAppealsFinishedObjectType } from '../../../../api/appeal';

type VisibleAppealListCardProps = {
  uf: string;
  institution: string;
  year: number;
  data: GetAppealsFinishedObjectType;
}

export const VisibleAppealListCard: FC<VisibleAppealListCardProps> = ({
  uf,
  institution,
  year,
  data,
}) => {
  const [questionEditOpen, setQuestionEditOpen] = useState(false);

  const navigate = useHistory();

  const handleEditModal = (): void => {
    setQuestionEditOpen(!questionEditOpen);
  };

  const handleCloseModal = useCallback(() => {
    setQuestionEditOpen(false);
  }, []);

  const handleNavigate = (): void => {
    navigate.push(`/admin/resources/new-appeal?institution=${institution}&year=${year}&appealId=${data.appealId}&examBooklet=${data.examBooklet}`);
  };

  return (
    <Col>
      <Space direction="vertical">
        <Typography style={{ fontSize: '14xp', fontWeight: 500 }}>{`Questão ${data.questionNumber} - Prova ${data.examVersion}`}</Typography>
        <Row>
          <FlagOutlined style={{ fontSize: '17px', color: '#2E6A44', marginRight: '4px' }} />
          <Typography style={{ fontSize: '12xp', fontWeight: 500 }}>{`Cabe Recurso: ${data.isAppealAccepted ? 'Sim' : 'Não'}`}</Typography>
        </Row>
        <Row wrap={false}>
          <div>
            <FontAwesomeIcon
              icon={faComment}
              style={{
                fontSize: '17px', color: '#2E6A44', marginRight: '4px', marginTop: '4px',
              }}
            />
          </div>
          <Markdown
            style={{
              marginRight: '8px',
              wordWrap: 'break-word',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxHeight: '6.3em',
            }}
            saveOnStorage={false}
          >
            {data.moderatorResponse}
          </Markdown>
        </Row>
        <Space>
          <Button
            shape="round"
            icon={<MenuOutlined />}
            onClick={handleNavigate}
          >
            editar comentário
          </Button>
          {/* <Button
            shape="round"
            icon={<MenuOutlined />}
            onClick={handleEditModal}
          >
            editar questão
          </Button> */}
        </Space>
      </Space>
    </Col>
  );
};
