import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Col, Divider, Input, message, Row, Select, SelectProps, Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { AppealAnswerCard } from './components/AppealAnswerCard';
import { getParam } from '../../../hooks/useSearchParam';
import { useGetAppealToRespond, useRespondAppeal } from '../../../api/appeal';
import { AristoMarkdownEditor } from '../../../components/AristoMarkdownEditor';
import Markdown from '../../../components/Markdown';
import { ReturnButton } from '../components/ReturnButton';

const cardStyle = {
  padding: '16px',
  borderRadius: '16px',
  margin: '16px 0px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  background: '#F9F9FF',
};

export const AppealAnswer: FC = () => {
  const appealId = getParam('appealId');
  const year = getParam('year');
  const [isAppealAccepted, setIsAppealAccepted] = useState<string>();
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useHistory();
  const [replyLink, setReplyImageLink] = useState<string>('');
  const [linkMarkdown, setLinkMarkdown] = useState<string>('');

  const fullComment = useMemo(() => {
    return linkMarkdown + comment;
  }, [comment, linkMarkdown]);

  const { data, loading, error } = useGetAppealToRespond(appealId);

  const [respondAppeal] = useRespondAppeal();

  useEffect(() => {
    if (error) {
      message.warning('Recurso já resevado!');
      navigate.push('/admin/resources/appeals');
    }
  }, [error, navigate]);

  const appeal = useMemo(() => {
    return data?.getAppealToRespond;
  }, [data]);

  const options : SelectProps['options'] = [
    {
      value: 'Sim',
      label: <span>Sim</span>,
    },
    {
      value: 'Não',
      label: <span>Não</span>,
    },
  ];

  const handleAnswerAppeal = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      await respondAppeal({
        variables: {
          appealId,
          moderatorResponse: fullComment,
          isAppealAccepted: isAppealAccepted === 'Sim',
        },
      });
      message.success('Recurso respondido!');
      navigate.push('/admin/resources/appeals');
    } catch (e) {
      console.log(e);
      message.error('Erro ao responder o recurso!');
    }
    setIsLoading(false);
  }, [appealId, fullComment, isAppealAccepted, navigate, respondAppeal]);

  const handleAddUrl = useCallback(() => {
    setLinkMarkdown(`![ ](${replyLink})\n\n`);
  }, [replyLink]);

  const disabled = useMemo(() => {
    return !comment.length || isLoading || loading || isAppealAccepted === undefined || !isAppealAccepted?.length || linkMarkdown.length === 0;
  }, [comment.length, isAppealAccepted, isLoading, linkMarkdown.length, loading]);

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0px auto' }}>
      <ReturnButton title="" />
      {(loading || !appeal) ? (
        <>
          <div style={{ ...cardStyle, backgroundColor: '#F0F0F0', height: '200px' }} />
          <div style={{ ...cardStyle, backgroundColor: '#F0F0F0', height: '400px' }} />
        </>
      ) : (
        <>
          <AppealAnswerCard data={appeal} year={Number(year ?? 2024)} />
          <Col style={cardStyle}>
            <Typography style={{ marginBottom: '8px' }}>Cabe Recurso</Typography>
            <Select
              style={{ width: '100%' }}
              placeholder="escolha uma opção"
              options={options}
              value={isAppealAccepted}
              onChange={setIsAppealAccepted}
            />
            <Divider style={{ margin: '16px 0px' }} />
            <Typography style={{ marginBottom: '8px', fontSize: 16 }}>Questão</Typography>
            <Row gutter={24}>
              <Col span={14}>
                <Typography
                  style={{
                    marginBottom: '8px',
                    fontSize: 12,
                    background: '#D9E3FD',
                    padding: '4px 8px',
                    borderRadius: 4,
                  }}
                >
                  Insira abaixo o link da imagem da questão e das alternativas
                </Typography>
                <Input
                  type="text"
                  placeholder="Link da imagem"
                  value={replyLink}
                  onChange={e => setReplyImageLink(e.target.value.replaceAll(')', '%29').replaceAll('(', '%28'))}
                  style={{ marginBottom: 8 }}
                />
                <Row justify="end">
                  <Button
                    disabled={replyLink.length === 0}
                    shape="round"
                    type="primary"
                    onClick={handleAddUrl}
                    className="my-button"
                  >
                    Adicionar imagem
                  </Button>
                </Row>
              </Col>
              <Col span={10}>
                <Markdown
                  style={{
                    minHeight: '300px',
                    padding: '16px',
                  }}
                  saveOnStorage={false}
                >
                  {linkMarkdown}
                </Markdown>
              </Col>
            </Row>
            <Divider style={{ margin: ' 16px 0px' }} />
            <Row wrap={false}>
              <div style={{ width: '50%' }}>
                <AristoMarkdownEditor
                  body={comment}
                  onUpdate={e => setComment(e)}
                  disabled={isLoading}
                />
              </div>
              <div style={{ width: '50%' }}>
                <Markdown
                  style={{
                    marginTop: '40px',
                    minHeight: '300px',
                    padding: '16px',
                  }}
                  saveOnStorage={false}
                >
                  {comment}
                </Markdown>
              </div>
            </Row>
            <Row justify="end">
              <Button
                shape="round"
                icon={<FontAwesomeIcon icon={faCommentDots} style={{ marginRight: '4px' }} />}
                onClick={handleAnswerAppeal}
                disabled={disabled}
                className="my-button"
              >
                Responder
              </Button>
            </Row>
          </Col>
        </>
      )}
    </div>
  );
};
