import {
  Button,
  Col, Row, Select, Space, Typography,
} from 'antd';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useGetInstitutionList } from '../../../../api/institutions';

const { Text } = Typography;

type BookletFilterProps = {
  onCreatingMode: () => void;
  disabledAdd: boolean;
  onUf: (v?: string) => void;
  onInstitution: (v?: string) => void;
  onYear: (v?: number) => void;
}

const BookletFilter: FC<BookletFilterProps> = ({
  onCreatingMode: onAdd, disabledAdd,
  onInstitution,
  onUf,
  onYear,
}) => {
  const [uf, setUf] = useState<string>();
  const [institution, setInstitution] = useState<string>();
  const [year, setYear] = useState<number>();

  const { data, loading } = useGetInstitutionList();

  const ufList = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    const result = [...data.getInstitutionListAdmin];
    result.sort((a, b) => {
      const ufA = a.uf.toUpperCase();
      const ufB = b.uf.toUpperCase();
      if (ufA < ufB) {
        return -1;
      }
      if (ufA > ufB) {
        return 1;
      }
      return 0;
    });
    return result;
  }, [data, loading]);

  const institutionList = useMemo(() => {
    const inst = ufList?.find(ufItem => ufItem.uf === uf)?.institutions ?? [];
    const result = [...inst];
    return result.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }, [uf, ufList]);

  const yearList = useMemo(() => {
    const yearsFind = institutionList?.find(inst => inst.name === institution)?.exams.map(e => e.year) ?? [];
    const yearsCopy = Array.from(new Set(yearsFind));
    return yearsCopy.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }, [institution, institutionList]);

  const ufOptions = useMemo(() => {
    return ufList?.map(e => ({ value: e.uf, label: e.uf })) || [];
  }, [ufList]);

  const institutionOptions = useMemo(() => {
    return institutionList?.map(e => ({ value: e.name, label: e.name })) || [];
  }, [institutionList]);

  const yearOptions = useMemo(() => {
    console.log(yearList);
    return yearList?.map(e => ({ value: e, label: e })) || [];
  }, [yearList]);

  const handleUf = useCallback((v: string) => {
    setYear(undefined);
    onYear(undefined);
    setInstitution(undefined);
    onInstitution(undefined);
    setUf(v);
    onUf(v);
  }, [onInstitution, onUf, onYear]);

  const handleInstitution = useCallback((v: string) => {
    setYear(undefined);
    onYear(undefined);
    setInstitution(v);
    onInstitution(v);
  }, [onInstitution, onYear]);

  const handleYear = useCallback((v: number) => {
    setYear(v);
    onYear(v);
  }, [onYear]);

  return (
    <Row align="bottom">
      <Space style={{ flex: 1 }}>
        <Col>
          <Space style={{ marginBottom: 4 }}>
            <Text strong>Filtrar provas</Text>
          </Space>
          <Row>
            <Space style={{ marginRight: 10 }}>
              <Select
                placeholder="UF"
                options={ufOptions}
                onChange={handleUf}
                value={uf}
                allowClear
                className="my-select"
                style={{ width: 69 }}
              />
            </Space>
            <Space style={{ marginRight: 10 }}>
              <Select
                placeholder="Instituição"
                options={institutionOptions}
                onChange={handleInstitution}
                value={institution}
                allowClear
                style={{ width: 202 }}
                className="my-select"
              />
            </Space>
            <Space style={{ marginRight: 10 }}>
              <Select
                placeholder="Ano da prova"
                options={yearOptions}
                onChange={handleYear}
                value={year}
                allowClear
                className="my-select"
                style={{ width: 137 }}
              />
            </Space>
          </Row>
        </Col>
      </Space>
      <Space>
        <Button
          onClick={onAdd}
          disabled={disabledAdd}
          shape="round"
          type="primary"
          size="large"
          className="my-button"
        >
          Adicionar prova
        </Button>
      </Space>
    </Row>
  );
};

export default BookletFilter;
