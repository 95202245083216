import { LoadingOutlined } from '@ant-design/icons';
import {
  Col,
  Tag, Typography,
} from 'antd';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseContentType, useFindLessonsWithContentId } from '../api/lessons';
import { useCourses } from '../hooks/useCourses';

const { Text } = Typography;

interface ContentInLessonTagProps {
  contentId: string;
  contentType: BaseContentType;
}

export function ContentInLessonTag({
  contentId, contentType,
}: ContentInLessonTagProps) {
  const { data, loading } = useFindLessonsWithContentId(contentId, contentType);
  const { courses, loading: coursesLoading } = useCourses();
  const history = useHistory();

  const lessons = useMemo(() => {
    const result: { title: string, id: string }[] = [];
    if (data && data[contentType]) {
      data[contentType].forEach(item => {
        item.courses.forEach(c => {
          const courseItem = courses.find(course => course._id === c);
          const courseText = courseItem ? ` | ${courseItem.title}` : '';
          result.push({ title: `${item.lessonTitle}${courseText}`, id: item.lessonId });
        });
      });
    }
    return result;
  }, [contentType, courses, data]);

  if (loading || coursesLoading) {
    return <LoadingOutlined />;
  }

  return (
    <Col>
      <Text>Conteúdo presente nas aulas:</Text>
      {lessons.sort((a, b) => (b > a ? -1 : 1)).map(item => {
        return (
          <Col span={12} style={{ marginBottom: '8px' }}>
            <button
              type="button"
              style={{
                background: 'none', border: 'none', cursor: 'pointer', margin: 0, padding: 0,
              }}
              onClick={() => history.push(`/admin/editar-aula?id=${item.id}`)}
            >
              <Tag color="#108ee9">{item.title}</Tag>
            </button>
          </Col>
        );
      })}
    </Col>
  );
}
